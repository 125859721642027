<template>
  <v-row no-gutters>
    <v-col cols="12" sm="4">
      <v-select
        v-model="selectedChild"
        :items="branch"
        item-text="codename"
        item-value="selected"
        :label="title"
        multiple
        outlined
        chips
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action>
              <v-icon :color="branch.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>

        <template v-slot:selection="{ item, index }" v-if="!selectedChild"></template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0 && isCompareArray">
            <span>{{ "ALL" }}</span>
          </v-chip>
          <v-chip v-else-if="!isCompareArray">
            <span>{{ item.codename }}</span>
          </v-chip>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MultipleSelected',
  data() {
    return {
      selectedChild: (this.selectedBranch && this.selectedBranch.length > 0) ? this.selectedBranch : null,
      rules: this.rule,
      isCompareArray: true,
    }
  },
  props: {
    selectedBranch: {type: Array},
    branch: {type: Array},
    title: {type: String},
    syncData: {type: Function},
    rule: {type: Function},
    isEditMode: {type: Boolean}
  },
  methods: {
    compareArray() {
      const allBranch = this.branch.map(({codename}) => codename).sort()
      const sortSelected = this.selectedChild.filter(value => allBranch.includes(value)).sort()
      return JSON.stringify(allBranch) === JSON.stringify(sortSelected)
    },
    toggle() {
      this.$nextTick(() => {
        const allBranch = this.branch.map(({codename}) => codename)
        const selectedChild = this.selectedChild || []
        const noPermissionChild = selectedChild.filter(value => !allBranch.includes(value))

        if (this.selectedSomeBranch) {
          this.selectedChild = noPermissionChild
        } else {
          this.selectedChild = [...noPermissionChild, ...this.branch.map(arr => arr.codename)]
        }
        this.syncData(this.selectedChild)
      })
    }
  },
  mounted() {
    if (this.selectedChild) {
      this.selectedChild = this.selectedBranch
        .map(({codename, selected}) => selected ? codename : null)
        .filter(v => v !== null)
      this.isCompareArray = this.compareArray(this.selectedBranch)
    }
  },
  computed: {
    selectedAllBranch() {
      const allBranch = this.branch.map(({codename}) => codename).sort()
      const selectedChild = this.selectedChild || []
      const sortSelected = selectedChild.filter(value => allBranch.includes(value)).sort()
      return JSON.stringify(allBranch) === JSON.stringify(sortSelected)
    },
    selectedSomeBranch() {
      const allBranches = this.branch.map(({codename}) => codename)
      const selectedChild = this.selectedChild || []
      return selectedChild.filter(value => allBranches.includes(value)).length > 0
    },
    icon() {
      if (this.selectedAllBranch) return 'mdi-close-box'
      if (this.selectedSomeBranch) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    selectedChild() {
      if (this.selectedChild) {
        this.syncData(this.selectedChild)
        this.isCompareArray = this.compareArray()
      }
    },
    branch() {
      if (this.branch) {
        if (!this.selectedChild) {
          this.selectedChild = this.selectedBranch
        }
        this.syncData(this.selectedChild)
        this.isCompareArray = this.compareArray()
      }
    },
  }
}
</script>
<style scoped>
</style>
