<template>
  <v-dialog v-model="show" persistent width="374">
    <v-card>
      <template slot="progress">
        <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
        ></v-progress-linear>
      </template>

      <v-img
          v-if="image"
          max-width="100%"
          height="250"
          v-bind:src="image"
      ></v-img>

      <v-card-title>Audience</v-card-title>
      <v-card-text>
        <div class="my-4 subtitle-1">
          Type : {{object.type}}
        </div>

        <div v-if="object.type === 'customize'">region: {{ object.region }}</div>
        <div v-if="object.type === 'customize'"> gender: {{ object.gender }}</div>
        <div v-if="object.type === 'customize'">min age: {{ object.min_age }}</div>
        <div v-if="object.type === 'customize'"> max age: {{ object.max_age }}</div>
        <div v-if="object.type === 'customize'"> register from: {{ object.register_date_from }}</div>
        <div v-if="object.type === 'customize'"> register to: {{ object.register_date_to }}</div>


      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>Organizations</v-card-title>

      <v-card-text>
        <v-chip-group
            active-class="deep-purple accent-4 white--text"
            column
        >
          <v-chip v-for="org in this.object.orgs"
                  :key="org.org_codename">{{ org.org_codename }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-layout justify-center>
        <v-card-actions>
          <v-btn
              color="primary"
              text
              @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-layout>
    </v-card>
    <!--    <v-card-text>-->
    <!--      <div align="center" style="padding-top: 30px">-->
    <!--        <v-img-->
    <!--            v-bind:src="image"-->
    <!--            class="grey lighten-2"-->
    <!--            max-width="40%"-->
    <!--            contain-->
    <!--            max-height="auto"-->
    <!--        ></v-img>-->
    <!--      </div>-->
    <!--      <v-col class="container" style="padding-top: 50px">-->
    <!--        <p> organization: {{ orgs }} </p>-->
    <!--        <p> type: {{ object.type }} </p>-->
    <!--        <div v-if="object.type === 'customize'">-->
    <!--          <p> region: {{ object.region }} </p>-->
    <!--          <p> gender: {{ object.gender }} </p>-->
    <!--          <p> min_age: {{ object.min_age }} </p>-->
    <!--          <p> max_age: {{ object.max_age }} </p>-->
    <!--          <p> register_date_from: {{ object.register_date_from }} </p>-->
    <!--          <p> register_date_to: {{ object.register_date_to }} </p>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--      <v-row no-gutters>-->
    <!--        <v-col class="container" cols="5" sm="5">-->
    <!--          <div style="margin-top: 20px">-->
    <!--            <h2>{{ titleThai }}</h2>-->
    <!--          </div>-->
    <!--          <div style="margin-top: 20px">-->
    <!--            <p v-html="descriptionThai"></p>-->
    <!--          </div>-->
    <!--        </v-col>-->
    <!--        <v-col class="container" cols="1" sm="1"></v-col>-->
    <!--        <v-col class="container" cols="6" sm="5">-->
    <!--          <div style="margin-top: 20px">-->
    <!--            <h2>{{ titleEng }}</h2>-->
    <!--          </div>-->
    <!--          <div style="margin-top: 20px">-->
    <!--            <p v-html="descriptionEng"></p>-->
    <!--          </div>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </v-card-text>-->
    <!--    </v-card>-->
  </v-dialog>

</template>

<script>
import {isUrl} from '../../helper/helper'

export default {
  name: 'Preview',
  props: {
    closePreivew: {type: Function},
    show: {type: Boolean},
    object: {type: Object}
  },
  computed: {
    orgs: function () {
      return this.object.orgs.map(({org_codename}) => org_codename).toString()
    },
    titleThai: function () {
      if (this.object.contents) {
        return this.object.contents[0].code === 'th'
            ? this.object.contents[0].title
            : this.object.contents[1].title
      } else {
        return ''
      }
    },
    titleEng: function () {
      if (this.object.contents) {
        return this.object.contents[0].code === 'en'
            ? this.object.contents[0].title
            : this.object.contents[1].title
      } else {
        return ''
      }
    },
    descriptionThai: function () {
      if (this.object.contents) {
        return this.object.contents[0].code === 'th'
            ? this.object.contents[0].description
            : this.object.contents[1].description
      } else {
        return ''
      }
    },
    descriptionEng: function () {
      if (this.object.contents) {
        return this.object.contents[0].code === 'en'
            ? this.object.contents[0].description
            : this.object.contents[1].description
      } else {
        return ''
      }
    },
    image: function () {
      return isUrl(this.object.image) ? `${this.object.image}?${new Date().getTime()}` : ''
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.closePreivew()
    }
  }
}
</script>

<style scoped>
.container {
  border-color: gray;
  padding-top: 20px;
}

.underline {
  text-decoration: line-through;
}

.text_price {
  color: deepskyblue;
}
</style>
