<template>
  <v-container class="px-10" fluid>
    <v-preview
      :show="isPreview"
      :closePreivew="closePreview"
      :object="notificationObject"
    />
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <h1 class="headline">Notifications</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          v-if="hasPermCreate()"
          large
          color="primary"
          :to="{name: 'notification-create'}"
          ><v-icon left>mdi-plus</v-icon>Add Notification</v-btn
        >
      </v-col>
    </v-row>

    <!-- Search and Filter -->
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="filters.type"
          @input="selectType"
          :items="filters.dropdownType"
          label="Type"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="3"></v-col>
      <v-col>
        <v-text-field
          @input="textSearch"
          v-model="filters.searchPhase"
          class="seachBox"
          append-icon="search"
          label="Search by title"
          single-line
          hide-details
          @click:clear="clearSearch()"
          clearable
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-multiple-selected
          title="Branch *"
          :branch="orgList.all"
          :selectedBranch="orgList.selected"
          :syncData="syncListSelectedData"
          :isEditMode="listMode"
        ></v-multiple-selected>
      </v-col>
    </v-row>
    <!-- Data table -->
    <v-card>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          v-model="selected"
          :loading="isLoading"
          :options.sync="options"
          :headers="table.headers"
          :items="notificationsList"
          :items-per-page="table.pageSize"
          :server-items-length="totalNotifications"
          :footer-props="{'items-per-page-options': table.itemPerPages}"
          @input="selectId"
          show-select
          class="elevation-1"
          item-key="id"
          fixed-header
          style="width: 100%"
          :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
        >
        <template v-slot:item.content="{item}" style="width: 130px">
          <v-list-item-content
            class="d-inline-block text-truncate"
            style="width: 130px"
          >
            <v-list-item-title> {{ item.title_th }} </v-list-item-title>
            <v-list-item-subtitle> {{ item.title_en }} </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-slot:item.create_date="{item}">{{
          dateFmt(item.create_date)
        }}</template>
        <template v-slot:item.publish_date="{item}">{{
          dateFmt(item.publish_date)
        }}</template>
        <template v-slot:item.orgs="{item}">
          <v-tooltip left>
            <template v-slot:activator="{on, attrs}">
              <span v-bind="attrs" v-on="on">
                {{ item.orgs.length }}
              </span>
            </template>
            <span>{{ item.orgs.map(org => org.org_codename).toString() }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action="{item}">
          <div>
            <v-icon small class="px-2" @click="showPreview(item.id)"
              >search</v-icon
            >
          </div>
        </template>
      </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {
  convertDate,
  hasPermision,
  searchHandler,
  convertSelectedData
} from '../../helper/helper'
import MultipleSelected from '../../components/ListMultipleSelected'
import Preview from './Preview'

export default {
  components: {
    'v-preview': Preview,
    'v-multiple-selected': MultipleSelected
  },

  computed: {
    ...mapState('notification', [
      'orgList',
      'isDelete',
      'isPreview',
      'notificationsList',
      'isLoading',
      'totalNotifications',
      'updateIdList',
      'notificationObject'
    ])
  },
  created() {
    this.setListOrgInfo()
  },
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    hasPermCreate() {
      return hasPermision('CanAddNotification')
    },
    hasPermEdit() {
      return hasPermision('CanEditNotification')
    },
    hasPermDelete() {
      return hasPermision('CanDeleteNotification')
    },
    clearSearch() {
      this.setTextSearch('')
      this.setPage(1)
      this.fetch()
    },
    async showPreview(id) {
      await this.setNotificationPreview(id)
    },
    closePreview() {
      this.setShowPreview(false)
    },
    confirmDelete() {
      this.deletePkg()
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup(id) {
      this.setDeleteId(id)
      this.setDeletePopup(true)
    },
    selectId(event) {
      this.selected = event
      this.setSelectedId(event)
    },
    statusColor(status) {
      return this.colorsLookup[status]
    },
    statusColorPublish(status) {
      if (status) {
        return 'green'
      }
      return 'red'
    },
    setUpdateStatus(event) {
      this.action.status = event
    },
    updateStatusID() {
      if (this.selected.length > 0) this.isUpdate = true
    },
    confirmUpdate() {
      this.isUpdate = false
      this.update(this.action.status)
      this.selected = []
    },
    closePopup() {
      this.isUpdate = false
    },
    dateFmt: date => convertDate(date),
    selectType() {
      this.setFilterType(this.filters.type)
      this.fetch()
    },
    textSearch() {
      this.timer = searchHandler(this.timer, clearTimeout, () => {
        this.setTextSearch(this.filters.searchPhase.trim())
        this.fetch()
      })
    },
    syncListSelectedData(data) {
      this.orgList.selected = convertSelectedData(
        data,
        this.orgList.all,
        this.orgList.selected
      )
      this.setPage(1)
      this.options.page = 1
      this.fetch()
    },
    ...mapActions('notification', [
      'fetch',
      'setPage',
      'setPageSize',
      'setFilterType',
      'setTextSearch',
      'setFieldSort',
      'setSort',
      'setSelectedId',
      'update',
      'setDeletePopup',
      'setShowPreview',
      'setNotificationPreview',
      'resetList',
      'setListOrgInfo'
    ])
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  data() {
    return {
      heightOptions: {
        header: 500,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      filters: {
        type: 'All',
        searchPhase: '',
        dropdownType: [
          {text: 'All', value: ''},
          {text: 'All target', value: 'all'},
          {text: 'Customized target', value: 'customize'}
        ]
      },
      listMode: false,
      isUpdate: false,
      selected: [],
      options: {},
      action: {
        status: '',
        dropdownActions: ['Active', 'Inactive']
      },
      colorsLookup: {
        0: 'green',
        1: 'red',
        2: 'yellow accent-4'
      },
      table: {
        page: 1,
        pageSize: 25,
        itemPerPages: [25, 50, 100],
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: true,
            value: 'id'
          },
          {text: 'Title', align: 'left', value: 'content', sortable: true},
          {
            text: 'Created',
            align: 'center',
            value: 'create_date',
            sortable: true
          },
          {
            text: 'Publish date',
            align: 'center',
            value: 'publish_date',
            sortable: true
          },
          {
            text: 'Branch',
            align: 'center',
            value: 'orgs',
            sortable: false
          },
          {text: 'Actions', align: 'center', value: 'action', sortable: false}
        ]
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          exact: true,
          to: '/'
        },
        {
          text: 'Notification',
          disabled: true,
          link: true,
          to: '/'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
    filters: {
      handler() {
        this.options.page = 1
      },
      deep: true
    }
  }
}
</script>
